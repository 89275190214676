<template>
  <div>
    <Dialog ref="dialog" :config="config"></Dialog>
    <div id="EmployeesAccount" style="padding-bottom: 26px">
      <div class="EmployeesAccountTitle flexAndCenter">
        <div class="fontSize20 color333 fontWeightBold">
          {{ step === 0 ? $fanyi("个人信息") : updateText }}
        </div>
      </div>
      <!--      个人信息-->
      <div class="EmployeesAccountListContainer" v-show="step === 0">
        <div class="employeesAccountContainer flexAndCenter">
          <div class="label">{{ $fanyi("名字") }}：</div>
          <div class="value fontSize14 color000">{{ userInfo.realname }}</div>
          <el-popover trigger="hover" placement="top-start">
            <div>{{ $fanyi("名字需要修改，请与业务员进行联系") }}</div>
            <img
                slot="reference"
                src="../../../../assets/4.png"
                style="width: 20px; margin-left: 10px; cursor: pointer"
            />
          </el-popover>
        </div>
        <div class="employeesAccountContainer flexAndCenter">
          <div class="label">{{ $fanyi("邮箱") }}：</div>
          <div class="value fontSize14 color000">
            <div>{{ userInfo.email }}</div>
            <div
                class="updateBtn"
                @click="
                step++;
                updateText = $fanyi('邮箱变更');
              "
            >
              {{ $fanyi("变更") }}
            </div>
          </div>
          <!-- <div
            class="verificationTextContainer"
            v-if="userInfo.email_is_checked == 0"
          >
            <img src="../../../../assets/verifyFalse.png" alt="" />
            <div>{{ $fanyi("未验证") }}？</div>
            <div class="cursorPointer colorB4272B" @click="showVerificationDialog('email')"
                 style="text-decoration: underline;">{{ $fanyi("立即验证") }}
            </div>
          </div>
          <div class="verificationTextContainer" v-else>
            <img src="../../../../assets/verifyTrue.png" alt="" />
            <div>{{ $fanyi("已验证") }}</div>
          </div> -->
        </div>
        <div class="employeesAccountContainer flexAndCenter">
          <div class="label">{{ $fanyi("座机") }}：</div>
          <el-input v-model="userInfo.tel" auto-complete="new-password"/>
        </div>
        <div class="employeesAccountContainer flexAndCenter">
          <div class="label">{{ $fanyi("手机号") }}：</div>
          <div class="value fontSize14 color000">
            <div>{{ userInfo.mobile }}</div>
            <div
                class="updateBtn"
                @click="
                step++;
                updateText = $fanyi('手机号变更');
              "
            >
              {{ $fanyi("变更") }}
            </div>
          </div>
          <!-- <div class="verificationTextContainer" v-if="userInfo.mobile_is_checked==0">
            <img src="../../../../assets/verifyFalse.png" alt="">
            <div>{{ $fanyi("未验证") }}？</div>
            <div class="cursorPointer colorB4272B" @click="showVerificationDialog('mobile')"
                 style="text-decoration: underline;">{{ $fanyi("立即验证") }}
            </div>
          </div>
          <div class="verificationTextContainer" v-else>
            <img src="../../../../assets/verifyTrue.png" alt="">
            <div>{{ $fanyi("已验证") }}</div>
          </div> -->
        </div>
        <div class="employeesAccountContainer flexAndCenter">
          <el-button
              style="margin-left: 132px"
              type="primary"
              @click="
              step++;
              updateText = $fanyi('密码变更');
            "
          >
            {{ $fanyi("重置密码") }}
          </el-button>
        </div>
        <div :style="isRegister==true?'background: #F6F6F6;':''" class="accountAssociationContainer">
          <div class="accountAssociationLeft">
            <div class="item">
              <img alt="" src="../../../../assets/userDetail/1688.png">
              <div>https://www.1688.com/</div>
            </div>
            <div class="item">
              <img alt="" src="../../../../assets/userDetail/taobao.png">
              <div>https://www.taobao.com/</div>
            </div>
            <div class="item">
              <img alt="" src="../../../../assets/userDetail/tmall.png">
              <div>https://www.tmall.com/</div>
            </div>
          </div>
          <div class="accountAssociationRight">
            <div class="icon" @click="accountSynchronizationPageJump">？</div>
            <div v-if="isRegister==false" class="text" @click="associationDialogVisible=true">今すぐ連結へ</div>
            <div v-else class="text" style="color: #50C136;">連結済み</div>
          </div>
        </div>
        <classificationInterest v-if="!$fun.isOfficialWeb()"/>
        <div class="employeesAccountContainer flexAndCenter">
          <div class="label">{{ $fanyi("注意事项") }}：</div>
          <el-input
              v-model="userInfo.client_remark"
              resize="none"
              type="textarea"
          />
        </div>
        <div v-show="step === 0" class="EmployeesAccountFooter">
          <el-button type="primary" @click="updateUserMessage">{{
              $fanyi("保存")
            }}
          </el-button>
        </div>
        <div class="employeesAccountContainer flexAndCenter">
          <div class="label">{{ $fanyi("优惠券兑换") }}：</div>
          <el-button type="primary" @click="couponRedemptionDialogVisible=true">{{
              $fanyi("立即兑换")
            }}
          </el-button>
        </div>
      </div>
      <!--      选择要更改的资料-->
      <div v-show="step > 0">
        <div
            v-show="step === 1"
            class="flexAndCenter"
            style="margin-left: 120px; margin-top: 61px"
        >
          <div
              v-for="(item, index) in selectVerificationModeList"
              :key="index"
              class="selectVerificationModeContainer"
              style="margin-right: 67px"
          >
            <img
                v-if="index === 0"
                :src="item.icon"
                style="width: 51px; margin-bottom: 38px; height: 60px"
            />
            <img
                v-else-if="index === 1"
                :src="item.icon"
                style="width: 41px; margin-bottom: 37px; height: 60px"
            />
            <img
                v-else
                :src="item.icon"
                style="width: 60px; margin-bottom: 52px; height: 44px"
            />
            <div
                class="fontSize24 fontWeightBold color333"
                style="margin-bottom: 74px"
            >
              {{ item.name }}
            </div>
            <el-button type="primary" @click="getVerificationMode(item.type)">{{
                $fanyi("确定")
              }}
            </el-button>
          </div>
        </div>
        <!--        步骤条-->
        <div v-show="step > 1">
          <div class="stepGroup">
            <div class="step">
              <div
                  :class="{ isStep: step == 2 }"
                  class="item"
                  style="margin-right: 5px"
              >
                <div class="flexAndCenter">
                  <span> 1 </span>
                  <div>{{ $fanyi("身份认证") }}</div>
                </div>
                <div
                    :class="{ isStepBorder: step == 2 }"
                    class="newIsStepBorder"
                ></div>
              </div>
              <div
                  :class="{ isStep: step == 3 }"
                  class="item"
                  style="margin-right: 5px"
              >
                <div class="flexAndCenter">
                  <span>2</span>
                  <div>{{ updateText }}</div>
                </div>
                <div
                    :class="{ isStepBorder: step == 3 }"
                    class="newIsStepBorder"
                ></div>
              </div>
              <div class="item" :class="{ isStep: step == 4 }">
                <div class="flexAndCenter">
                  <span>3</span>
                  <div>{{ $fanyi("更改成功") }}</div>
                </div>
                <div
                    :class="{ isStepBorder: step == 4 }"
                    class="newIsStepBorder"
                ></div>
              </div>
            </div>
          </div>
          <div class="updateUserMessageContainer">
            <!--             密码验证开始-->
            <div
                v-show="verificationMode === 'password' && step === 2"
                class="passwordVerification"
                style="margin-left: 60px"
            >
              <div class="passwordInput flexAndCenter">
                <div>{{ $fanyi("请输入登录密码") }}：</div>
                <el-input
                    v-model="oldPassword"
                    show-password
                    style="width: 280px"
                    type="password"
                    @input="passwordChannelInputLimit"
                />
              </div>
              <el-button
                  type="primary"
                  @click="passwordVerificationAndNextStep"
              >{{ $fanyi("下一步") }}
              </el-button
              >
              <div
                  class="updateVerificationModeText"
                  @click="selectAttestationMode"
              >
                {{ $fanyi("其他认证方式") }}
              </div>
            </div>
            <!--             密码验证结束-->
            <!--             手机号验证开始-->
            <div
                v-show="verificationMode === 'iphone' && step === 2"
                class="passwordVerification"
                style="margin-left: 277px"
            >
              <div
                  class="iphoneAndEmailLabel flexAndCenter"
                  style="margin-bottom: 26px"
              >
                <div>{{ $fanyi("手机号") }}：</div>
                <div class="disabledInput">{{ userInfo.mobile }}</div>
              </div>
              <div
                  class="iphoneAndEmailCode flexAndCenter"
                  style="margin-left: 8px"
              >
                <div class="flexAndCenter">
                  <div style="color: #b4272b">*</div>
                  {{ $fanyi("验证码") }}：
                </div>
                <el-input
                    v-model="code"
                    style="width: 170px; margin-right: 10px"
                    @blur="verificationOldIphoneCode"
                />
                <el-button
                    v-show="show"
                    style="margin-left: 0"
                    type="primary"
                    @click="getOldIphoneVerificationCode"
                >
                  {{ $fanyi("获取验证码") }}
                </el-button>
                <el-button type="primary" v-show="!show" style="margin-left: 0">
                  {{ Countdown }} S
                </el-button>
              </div>
              <div class="errorText" style="margin-left: 112px">
                {{ $fanyi(codeErrorText) }}
              </div>
              <el-button
                  style="margin-left: 110px"
                  type="primary"
                  @click="iphoneVerificationAndNextStep"
              >
                {{ $fanyi("下一步") }}
              </el-button>
              <div
                  class="updateVerificationModeText"
                  style="margin-left: 110px"
                  @click="selectAttestationMode"
              >
                {{ $fanyi("其他认证方式") }}
              </div>
            </div>
            <!--             手机号验证结束-->
            <!--             邮箱验证开始-->
            <div
                v-show="verificationMode === 'email' && step === 2"
                class="passwordVerification"
                style="margin-left: 277px"
            >
              <div
                  class="iphoneAndEmailLabel flexAndCenter"
                  style="margin-bottom: 26px; margin-left: 16px"
              >
                <div>{{ $fanyi("邮箱") }}：</div>
                <div class="disabledInput">{{ userInfo.email }}</div>
              </div>
              <div
                  class="iphoneAndEmailCode flexAndCenter"
                  style="margin-left: 41px"
              >
                <div class="flexAndCenter">
                  <div style="color: #b4272b">*</div>
                  {{ $fanyi("验证码") }}：
                </div>
                <el-input
                    v-model="code"
                    style="width: 170px; margin-right: 10px"
                    @blur="verificationOldIphoneCode"
                />
                <el-button
                    v-show="show"
                    style="margin-left: 0"
                    type="primary"
                    @click="getOldEmailVerificationCode"
                >
                  {{ $fanyi("获取验证码") }}
                </el-button>
                <el-button type="primary" v-show="!show" style="margin-left: 0">
                  {{ Countdown }} S
                </el-button>
              </div>
              <div class="errorText" style="margin-left: 146px">
                {{ $fanyi(codeErrorText) }}
              </div>
              <el-button
                  style="margin-left: 141px"
                  type="primary"
                  @click="emailVerificationAndNextStep"
              >
                {{ $fanyi("下一步") }}
              </el-button>
              <div
                  class="updateVerificationModeText"
                  style="margin-left: 144px"
                  @click="selectAttestationMode"
              >
                {{ $fanyi("其他认证方式") }}
              </div>
            </div>
            <!--             邮箱验证结束-->
            <!--             手机号变更开始-->
            <div
                v-show="updateText === $fanyi('手机号变更') && step === 3"
                class="updateIphoneContainer"
            >
              <div class="iphoneInput flexAndCenter" style="margin-left: 80px">
                <div>{{ $fanyi("请输入新的手机号") }}：</div>
                <el-input
                    v-model="newIphone"
                    style="width: 280px"
                    @input="iphoneNumberVerify"
                />
                <img
                    v-show="newIphoneStatus === true"
                    src="../../../../assets/verifyTrue.png"
                />
                <img
                    v-show="newIphoneStatus === false"
                    src="../../../../assets/verifyFalse.png"
                />
              </div>
              <div style="margin-left: 288px">
                <div class="errorText">{{ errorText }}</div>
                <div class="iphoneVerificationCode flexAndCenter">
                  <div>{{ $fanyi("验证码") }}：</div>
                  <el-input
                      v-model="verificationCode"
                      style="width: 170px; margin-right: 10px"
                      @blur="verificationIphoneCode"
                  />
                  <el-button
                      v-show="show"
                      type="primary"
                      @click="getNewIphoneVerificationCode"
                  >
                    {{ $fanyi("获取验证码") }}
                  </el-button>
                  <el-button type="primary" v-show="!show">
                    {{ Countdown }} S
                  </el-button>
                </div>
                <div class="errorText" style="margin-left: 106px">
                  {{ $fanyi(codeErrorText) }}
                </div>
                <div class="nextStepBtnContainer" style="margin-left: 94px">
                  <el-button
                      type="primary"
                      @click="newIphoneVerificationAndNextStep"
                  >{{ $fanyi("下一步") }}
                  </el-button>
                </div>
              </div>
            </div>
            <!--             手机号变更结束-->
            <!--             邮箱变更开始-->
            <div
                v-show="updateText === $fanyi('邮箱变更') && step === 3"
                class="updateIphoneContainer"
            >
              <div class="iphoneInput flexAndCenter" style="margin-left: 64px">
                <div>{{ $fanyi("请输入新的邮箱") }}：</div>
                <el-input
                    v-model="newEmail"
                    style="width: 280px"
                    @input="emailNumberVerify"
                />
                <img
                    v-show="newIphoneStatus === true"
                    src="../../../../assets/verifyTrue.png"
                />
                <img
                    v-show="newIphoneStatus === false"
                    src="../../../../assets/verifyFalse.png"
                />
              </div>
              <div style="margin-left: 288px">
                <div class="errorText" style="margin-left: 104px">
                  {{ errorText }}
                </div>
                <div class="iphoneVerificationCode flexAndCenter">
                  <div>{{ $fanyi("验证码") }}：</div>
                  <el-input
                      v-model="verificationCode"
                      style="width: 170px; margin-right: 10px"
                      @blur="verificationEmailCode"
                  />
                  <el-button
                      v-show="show"
                      type="primary"
                      @click="getNewEmailVerificationCode"
                  >
                    {{ $fanyi("获取验证码") }}
                  </el-button>
                  <el-button type="primary" v-show="!show">{{ Countdown }} S
                  </el-button>
                </div>
                <div class="errorText" style="margin-left: 104px">
                  {{ $fanyi(codeErrorText) }}
                </div>
                <div class="nextStepBtnContainer" style="margin-left: 95px">
                  <el-button
                      type="primary"
                      @click="newEmailVerificationAndNextStep">{{ $fanyi("下一步") }}
                  </el-button>
                </div>
              </div>
            </div>
            <!--             邮箱变更结束-->
            <!--             密码变更开始-->
            <div
                v-show="updateText === $fanyi('密码变更') && step === 3"
                class="updateIphoneContainer"
                style="margin-left: 50px"
            >
              <div
                  class="passwordInput flexAndCenter"
                  style="margin-left: 64px"
              >
                <div>{{ $fanyi("请输入新密码") }}：</div>
                <el-input
                    v-model="newPassword"
                    show-password
                    style="width: 280px"
                    type="password"
                    @input="passwordNumberVerify"
                />
                <img
                    v-show="newIphoneStatus === true"
                    src="../../../../assets/verifyTrue.png"
                />
                <img
                    v-show="newIphoneStatus === false"
                    src="../../../../assets/verifyFalse.png"
                />
              </div>
              <div class="errorText" style="margin-left: 355px">
                {{ $fanyi(errorText) }}
              </div>
              <div class="passwordInput flexAndCenter">
                <div>{{ $fanyi("请再次输入新密码") }}：</div>
                <el-input
                    v-model="confirmPassword"
                    show-password
                    style="width: 280px"
                    type="password"
                    @input="confirmPasswordNumberVerify"
                />
                <img
                    v-show="confirmPasswordStatus === true"
                    src="../../../../assets/verifyTrue.png"
                />
                <img
                    v-show="confirmPasswordStatus === false"
                    src="../../../../assets/verifyFalse.png"
                />
              </div>
              <div class="errorText" style="margin-left: 355px">
                {{ $fanyi(codeErrorText) }}
              </div>
              <div class="nextStepBtnContainer"
                   style="margin-left: 352px; margin-top: 0">
                <el-button type="primary" @click="newPasswordNextStep">{{
                    $fanyi("下一步")
                  }}
                </el-button>
              </div>
            </div>
            <!--             密码变更结束-->
            <!--             修改成功开始-->
            <div
                v-show="step === 4"
                class="updateSuccessContainer flexAndCenter"
            >
              <img alt="" src="../../../../assets/icon-1.png"/>
              <div class="fontSize18 color333" style="margin: 26px 0 60px">
                {{ updateText }}{{ $fanyi("成功") }}
              </div>
              <el-button type="primary" @click="updateSuccessOperation">{{
                  $fanyi("确定")
                }}
              </el-button>
            </div>
            <!--             修改成功结束-->
          </div>
        </div>
      </div>
    </div>
    <div class="dialogContainer">
      <el-dialog
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :title="
          componentsName === 'verificationEmail'
            ? $fanyi('验证邮箱')
            : $fanyi('验证手机号')
        "
          :visible.sync="verificationDialogVisible"
          center
          width="340px"
      >
        <component :is="componentsName" ref="componentRef"/>
        <div style="width: 100%;display: flex;justify-content: center;margin-top: 30px;">
          <div class="submitBtn" @click="verificationCodeFunction">保存</div>
        </div>
      </el-dialog>
      <el-dialog
          :before-close="handleClose"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :visible.sync="couponRedemptionDialogVisible"
          center
          width="380px"
      >
        <div class="couponRedemptionFromContainer">
          <div style="margin-bottom: 10px">{{ $fanyi("请填写兑换码") }}:</div>
          <el-input v-model="giftExchangeFrom.code" @input="convertToCapitalLetter"/>
          <div style="margin:10px 0">{{ $fanyi("请填写到会登记的email地址") }}:</div>
          <el-input v-model="giftExchangeFrom.email" @input="emailFullyFormedCharacterConvert"/>
        </div>
        <div style="width: 100%;display: flex;justify-content: center;margin-top: 30px;">
          <div class="submitBtn" @click="submitGiftExchangeFrom">{{ $fanyi("保存") }}</div>
        </div>
      </el-dialog>
    </div>
    <div class="newDialogContainer">
      <el-dialog
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :show-close="false"
          :visible.sync="submitHintDialogVisible"
          width="372px"
      >
        <div class="dialogHeader flexAndCenter">
          <div>!</div>
          <div>{{ $fanyi("提示") }}</div>
        </div>
        <div class="dialogCenter">
          <div>
            {{
              verificationPassStatus === false
                  ? $fanyi("您输入的验证码不正确，请确认后重新输入")
                  : $fanyi("验证成功")
            }}
          </div>
        </div>
        <div class="dialogFooter">
          <div @click="cloneSubmitHintDialogVisible">{{ $fanyi("确定") }}</div>
        </div>
      </el-dialog>
      <el-dialog
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :show-close="false"
          :visible.sync="associationDialogVisible"
          width="580px"
      >
        <associationDialog @close="associationDialogVisible=false;"/>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Dialog from "../../../../components/public/Dialog";
import store from "@/store";
import associationDialog from '@/views/UserCenter/views/userDetail/components/accountAssociationDialog.vue'
import verificationEmail from "@/views/UserCenter/views/userDetail/components/verificationEmail";
import verificationIphone from "@/views/UserCenter/views/userDetail/components/verificationIphone";
import classificationInterest from '@/views/UserCenter/views/userDetail/components/classificationInterest.vue'

export default {
  data() {
    return {
      textarea: "",
      codeDisabled: false,
      associationDialogVisible: false,
      verificationDialogVisible: false,
      couponRedemptionDialogVisible: false,
      submitHintDialogVisible: false,
      verificationPassStatus: false,
      specialPlane: "",
      verificationCode: "",
      newIphoneStatus: "",
      confirmPasswordStatus: "",
      newPassword: "",
      errorText: "",
      checked: false,
      newIphone: "",
      newEmail: "",
      step: 0,
      iphone: "",
      Countdown: 60,
      codeErrorText: "",
      show: true,
      confirmPassword: "",
      oldPassword: "",
      codeStatus: false,
      updateText: this.$fanyi("密码验证"),
      verificationMode: "",
      selectVerificationModeList: [
        {
          icon: require("../../../../assets/password.png"),
          type: "password",
          name: this.$fanyi("密码验证"),
        },
        {
          icon: require("../../../../assets/iphone.png"),
          type: "iphone",
          name: this.$fanyi("手机号验证"),
        },
        {
          icon: require("../../../../assets/email.png"),
          type: "email",
          name: this.$fanyi("邮箱验证"),
        },
      ],
      timer: null,
      children_user_id: "",
      componentsName: "",
      code: "",
      childrenAccountMessageEditDialogVisible: false,
      sub_role: "",
      oldIphone: "",
      verificationType: "",
      isRegister: false,
      giftExchangeFrom: {
        email: '',
        code: ''
      },
      // 弹窗的属性
      config: {
        width: "480px",
        title: this.$fanyi("提示"),
        btnTxt: [this.$fanyi("确定"), this.$fanyi("取消")],
      },
    };
  },
  components: {
    Dialog,
    associationDialog,
    verificationEmail,
    verificationIphone,
    classificationInterest
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  created() {
    this.$api.alibabaUserRegisterList().then((res) => {
      this.isRegister = res.data.length > 0
    });
  },
  methods: {
    //更新客户座机与客户注意事项
    updateUserMessage() {
      if (this.userInfo.tel !== "" || this.userInfo.client_remark !== "") {
        let msg = this.$fanyi("是否提交座机与注意事项修改");
        this.$refs.dialog.open(
            // 文本,复杂的在组件里添加插槽
            msg,
            () => {
              // 确认按钮事件
              this.$api
              .updateAutoInfo({
                tel: this.userInfo.tel,
                client_remark: this.userInfo.client_remark,
              })
              .then((res) => {
                if (res.code != 0) return;
                this.$message({
                  type: "success",
                  message: this.$fanyi(res.msg),
                });
                this.getUserInfo();
              });
            },
            () => {
            }
        );
      }
    },
    //
    showVerificationDialog(val) {
      this.verificationType = val;
      val === "email"
          ? (this.componentsName = "verificationEmail")
          : (this.componentsName = "verificationIphone");
      this.verificationDialogVisible = true;
    },
    //验证code是否正确
    verificationCodeFunction() {
      if (this.verificationType === "email") {
        this.$api
        .toolCheckedValidationCode({
          check_number_type: "email",
          code: this.$refs.componentRef.value,
        })
        .then((res) => {
          if (res.code == 0) {
            this.verificationPassStatus = true;
          }
          this.verificationPassStatus = res.msg !== "验证码错误";
        });
      } else {
        this.$api
        .toolCheckedValidationCode({
          check_number_type: "mobile",
          code: this.$refs.componentRef.value,
        })
        .then((res) => {
          if (res.code == 0) {
            this.verificationPassStatus = true;
          }
        });
      }
      setTimeout(() => {
        this.submitHintDialogVisible = true;
      }, 1000);
    },
    accountSynchronizationPageJump() {
      window.open('/accountSynchronization')
    },
    //关闭提示弹窗
    cloneSubmitHintDialogVisible() {
      if (this.verificationPassStatus === false) {
        this.submitHintDialogVisible = false;
      } else {
        this.submitHintDialogVisible = false;
        this.verificationDialogVisible = false;
        this.getUserInfo();
      }
    },
    //手机号纯数字校验
    iphoneNumberVerify(e) {
      this.newIphoneStatus = true;
    },
    //邮箱格式校验
    emailNumberVerify(e) {
      let str = /^.+@.+$/i;
      if (!str.test(e)) {
        this.newIphoneStatus = false;
      }
      if (str.test(e)) {
        this.newIphoneStatus = true;
      }
    },
    //获取验证手机号的验证码
    getOldIphoneVerificationCode() {
      this.show = true;
      // 60秒倒计时
      this.show = false;
      this.timer = setInterval(() => {
        this.Countdown--;
        if (this.Countdown <= 0) {
          this.show = true;
          this.Countdown = 60;
          clearInterval(this.timer);
        }
      }, 1000);
      switch (this.updateText) {
        case this.$fanyi('密码变更'):
          this.$api.getUpdatePasswordVerificationCode({
            check_number_type: "mobile",
          })
          .then((res) => {
            if (res.code != 0) {
              this.$message.error(res.msg)
              this.show = true;
              this.Countdown = 60;
              clearInterval(this.timer);
            }
          });
          break;
        case this.$fanyi('邮箱变更'):
          this.$api.getUpdateEmailVerificationCode({
            check_number_type: "mobile",
          })
          .then((res) => {
            if (res.code != 0) {
              this.$message.error(res.msg)
              this.show = true;
              this.Countdown = 60;
              clearInterval(this.timer);
            }
          });
          break;
        case this.$fanyi('手机号变更'):
          this.$api.getUpdateMobileVerificationCode({
            check_number_type: "mobile",
          })
          .then((res) => {
            if (res.code != 0) {
              this.$message.error(res.msg)
              this.show = true;
              this.Countdown = 60;
              clearInterval(this.timer);
            }
          });
          break;
      }
    },
    convertToCapitalLetter(e) {
      this.giftExchangeFrom.code = e.toUpperCase();
      this.giftExchangeFrom.code = this.fullyFormedCharacterConvert(this.giftExchangeFrom.code);
    },
    emailFullyFormedCharacterConvert(e) {
      this.giftExchangeFrom.email = this.fullyFormedCharacterConvert(e);
    },
    fullyFormedCharacterConvert(val) {
      let sNum = val.toString(); //先转换成字符串类型
      sNum = sNum.replace(/１/g, '1')
      sNum = sNum.replace(/２/g, '2')
      sNum = sNum.replace(/３/g, '3')
      sNum = sNum.replace(/４/g, '4')
      sNum = sNum.replace(/５/g, '5')
      sNum = sNum.replace(/６/g, '6')
      sNum = sNum.replace(/７/g, '7')
      sNum = sNum.replace(/８/g, '8')
      sNum = sNum.replace(/９/g, '9')
      sNum = sNum.replace(/０/g, '0')
      sNum = sNum.replace(/＠/g, '@')
      return sNum
    },
    //提交礼物兑换
    submitGiftExchangeFrom() {
      let str = /^.+@.+$/i;
      if (this.giftExchangeFrom.code == '') {
        this.$message.error(this.$fanyi('请填写兑换码'))
        return
      }
      if (this.giftExchangeFrom.email == '') {
        this.$message.error(this.$fanyi('请填写到会登记的email地址'))
        return
      }
      if (!str.test(this.giftExchangeFrom.email)) {
        this.$message.error(this.$fanyi('请输入正确的邮箱地址'))
        return
      }
      this.$api.giftCodeExchange(this.giftExchangeFrom).then((res) => {
        if (res.code != 0) {
          this.$confirm(
              res.msg,
              '注意',
              {
                confirmButtonText: this.$fanyi('确定'), //确定
                cancelButtonText: this.$fanyi('取消'), //取消
                type: "warning",
                center: true,
                dangerouslyUseHTMLString: true,
              }
          ).then(() => {

          }).catch(() => {

          });
          return
        }
        this.$message.success(res.msg)
        this.couponRedemptionDialogVisible = false;
        this.giftExchangeFrom = {
          email: '',
          code: ''
        }
      });
    },
    handleClose(done) {
      this.giftExchangeFrom = {
        email: '',
        code: ''
      }
      done();
    },
    //校验验证手机号的验证码
    verificationOldIphoneCode() {
      switch (this.updateText) {
        case this.$fanyi("手机号变更"):
          this.$api
          .checkUpdateMobileValidationCode({
            check_number_type:
                this.verificationMode === "email" ? "email" : "mobile",
            code: this.code,
          })
          .then((res) => {
            if (res.msg === "验证码错误") {
              this.codeErrorText = this.$fanyi(res.msg);
            } else {
              this.codeStatus = true;
              this.codeErrorText = "";
            }
          });
          break;
        case this.$fanyi("邮箱变更"):
          this.$api
          .checkUpdateEmailValidationCode({
            check_number_type:
                this.verificationMode === "email" ? "email" : "mobile",
            code: this.code,
          })
          .then((res) => {
            if (res.code != 0) {
              this.codeErrorText = this.$fanyi(res.msg);
            } else {
              this.codeStatus = true;
              this.codeErrorText = "";
            }
          });
          break;
        case this.$fanyi("密码变更"):
          this.$api
          .checkUpdatePasswordValidationCode({
            check_number_type:
                this.verificationMode === "email" ? "email" : "mobile",
            code: this.code,
          })
          .then((res) => {
            if (res.code != 0) {
              this.codeErrorText = this.$fanyi(res.msg);
            } else {
              this.codeStatus = true;
              this.codeErrorText = "";
            }
          });
          break;
      }
    },
    //手机号校验成功
    iphoneVerificationAndNextStep() {
      if (this.codeStatus === true) {
        this.step++;
        this.show = true;
        this.Countdown = 60;
      }
    },
    //邮箱校验成功
    emailVerificationAndNextStep() {
      if (this.codeStatus === true) {
        this.step++;
        this.show = true;
        this.Countdown = 60;
      }
    },
    //获取验证邮箱的验证码
    getOldEmailVerificationCode() {
      this.show = true;
      this.show = false;
      this.timer = setInterval(() => {
        this.Countdown--;
        if (this.Countdown <= 0) {
          this.show = true;
          this.Countdown = 60;
          clearInterval(this.timer);
        }
      }, 1000);
      switch (this.updateText) {
        case this.$fanyi('密码变更'):
          this.$api
          .getUpdatePasswordVerificationCode({
            check_number_type: "email",
          })
          .then((res) => {
            if (res.code != 0) {
              this.$message.error(res.msg)
              this.show = true;
              this.Countdown = 60;
              clearInterval(this.timer);
            }
          });
          break;
        case this.$fanyi('手机号变更'):
          this.$api.getUpdateMobileVerificationCode({
            check_number_type: "email",
          })
          .then((res) => {
            if (res.code != 0) {
              this.$message.error(res.msg)
              this.show = true;
              this.Countdown = 60;
              clearInterval(this.timer);
            }
          });
          break;
        case this.$fanyi('邮箱变更'):
          this.$api.getUpdateEmailVerificationCode({
            check_number_type: "email",
          })
          .then((res) => {
            if (res.code != 0) {
              this.$message.error(res.msg)
              this.show = true;
              this.Countdown = 60;
              clearInterval(this.timer);
            }
          });
          break;
      }
    },
    //手机号验证码校验
    verificationIphoneCode() {
      this.$api
      .checkUpdateMobileValidationCode({
        new_mobile: this.newIphone,
        code: this.verificationCode,
        check_number_type: "mobile",
      })
      .then((res) => {
        if (res.code != 0) {
          this.codeErrorText = this.$fanyi(res.msg);
        } else {
          this.codeStatus = true;
          this.codeErrorText = "";
        }
      });
    },
    //邮箱验证码校验
    verificationEmailCode() {
      this.$api
      .checkUpdateEmailValidationCode({
        new_email: this.newEmail,
        check_number_type: "email",
        code: this.verificationCode,
      })
      .then((res) => {
        if (res.code != 0) {
          this.codeErrorText = this.$fanyi(res.msg);
        } else {
          this.codeStatus = true;
          this.codeErrorText = "";
        }
      });
    },
    //修改成功后的操作
    updateSuccessOperation() {
      if (this.updateText === this.$fanyi("密码变更")) {
        localStorage.removeItem("japan_user_token");
        localStorage.removeItem('gkValue');
        store.commit("userData", null);
        this.$fun.routerToPage("/login");
      } else {
        this.step = 0;
        this.code = "";
        this.newEmail = "";
        this.oldPassword = "";
        this.newIphone = "";
        this.verificationMode = "";
        this.verificationCode = "";
        this.newIphoneStatus = false;
        this.getUserInfo();
      }
    },
    getUserInfo() {
      this.$api.japanGetUserInfo().then((res) => {
        this.$store.commit("userData", res.data); // 用户信息存储
      });
    },
    //获取验证方式
    getVerificationMode(name) {
      this.verificationMode = name;
      this.step++;
    },
    //验证登录并进行下一步
    passwordVerificationAndNextStep() {
      this.$api
      .userCheckPassword({
        password: this.oldPassword,
      })
      .then((res) => {
        if (res.code != 0)
          return this.$message({
            type: "error",
            message: this.$fanyi(res.msg),
          });
        this.$message({
          type: "success",
          message: this.$fanyi(res.msg),
        });
        this.step++;
      });
    },
    //密码输入校验
    passwordChannelInputLimit(e) {
      this.oldPassword = e.replace(/[^A-Za-z0-9@#$%&*()_+{};"<>?~]/g, "");
    },
    //提交新手机号修改
    newIphoneVerificationAndNextStep() {
      if (this.newIphoneStatus === true && this.codeStatus === true) {
        if (this.verificationMode === "password") {
          this.$api
          .updateMobileViaOldPassword({
            password: this.oldPassword,
            new_mobile: this.newIphone,
          })
          .then((res) => {
            if (res.code != 0)
              return this.$message({
                type: "error",
                message: this.$fanyi(res.msg),
              });
            this.$message({
              type: "success",
              message: this.$fanyi(res.msg),
            });
            this.step++;
          });
        } else {
          this.$api
          .updateMobileViaValidationCode({
            new_mobile: this.newIphone,
            code: this.verificationCode,
          })
          .then((res) => {
            if (res.code != 0)
              return this.$message({
                type: "error",
                message: this.$fanyi(res.msg),
              });
            this.$message({
              type: "success",
              message: this.$fanyi(res.msg),
            });
            this.step++;
          });
        }
      }
    },
    //提交新邮箱修改
    newEmailVerificationAndNextStep() {
      if (this.newIphoneStatus === true && this.codeStatus === true) {
        if (this.verificationMode === "password") {
          this.$api
          .updateEmailViaOldPassword({
            password: this.oldPassword,
            new_email: this.newEmail,
          })
          .then((res) => {
            if (res.code != 0)
              return this.$message({
                type: "error",
                message: this.$fanyi(res.msg),
              });
            this.$message({
              type: "success",
              message: this.$fanyi(res.msg),
            });
            this.step++;
          });
        } else {
          this.$api
          .updateEmailViaValidationCode({
            new_email: this.newEmail,
            code: this.verificationCode,
          })
          .then((res) => {
            if (res.code != 0)
              return this.$message({
                type: "error",
                message: this.$fanyi(res.msg),
              });
            this.$message({
              type: "success",
              message: this.$fanyi(res.msg),
            });
            this.step++;
          });
        }
      }
    },
    //提交新密码修改
    newPasswordNextStep() {
      if (
          this.newIphoneStatus === true &&
          this.confirmPasswordStatus === true
      ) {
        if (this.verificationMode !== "password") {
          this.$api
          .updatePasswordViaValidationCode({
            new_password: this.newPassword,
            code: this.code,
            check_number_type:
                this.verificationMode === "email" ? "email" : "mobile",
          })
          .then((res) => {
            if (res.code != 0)
              return this.$message({
                type: "error",
                message: this.$fanyi(res.msg),
              });
            this.$message({
              type: "success",
              message: this.$fanyi(res.msg),
            });
            this.step++;
          });
        } else {
          this.$api
          .updatePasswordViaOldPassword({
            old_password: this.oldPassword,
            new_password: this.confirmPassword,
          })
          .then((res) => {
            if (res.code != 0)
              return this.$message({
                type: "error",
                message: this.$fanyi(res.msg),
              });
            this.$message({
              type: "success",
              message: this.$fanyi(res.msg),
            });
            this.step++;
          });
        }
      }
    },
    //校验密码格式
    passwordNumberVerify(e) {
      this.newPassword = e.replace(/[^A-Za-z0-9@]/g, "");
      let str = /^[0-9a-zA-Z@]{6,16}$/;
      if (!str.test(e)) {
        this.newIphoneStatus = false;
        this.errorText = this.$fanyi(
            "请输入 6 到 16 位包含大小写字母和数字的密码"
        );
      }
      if (str.test(e)) {
        this.newIphoneStatus = true;
        this.errorText = "";
      }
      if (this.confirmPassword != "") {
        if (str.test(e) && this.confirmPassword !== this.newPassword) {
          this.confirmPasswordStatus = false;
          this.codeErrorText = this.$fanyi("输入密码和前一次输入的不一致");
        }
        if (str.test(e) && this.confirmPassword === this.newPassword) {
          this.confirmPasswordStatus = true;
          this.codeErrorText = "";
        }
      }
    },
    //校验确认密码格式
    confirmPasswordNumberVerify(e) {
      this.confirmPassword = e.replace(/[^A-Za-z0-9@]/g, "");
      let str = /^[0-9a-zA-Z@]{6,16}$/;
      if (this.confirmPassword !== this.newPassword) {
        this.confirmPasswordStatus = false;
        this.codeErrorText = this.$fanyi("输入密码和前一次输入的不一致");
        return;
      }
      if (str.test(e) && this.confirmPassword === this.newPassword) {
        this.confirmPasswordStatus = true;
        this.codeErrorText = "";
      }
    },
    //获取新手机号的验证码
    getNewIphoneVerificationCode() {
      if (this.newIphoneStatus === true) {
        this.show = true;
        // 60秒倒计时
        this.show = false;
        this.timer = setInterval(() => {
          this.Countdown--;
          if (this.Countdown <= 0) {
            this.show = true;
            this.Countdown = 60;
            clearInterval(this.timer);
          }
        }, 1000);
        this.$api
        .getUpdateMobileVerificationCode({
          check_number_type: "mobile",
          new_mobile: this.newIphone,
        })
        .then((res) => {
          if (res.code != 0) {
            this.$message.error(res.msg)
            this.show = true;
            this.Countdown = 60;
            clearInterval(this.timer);
          }
        });
      }
    },
    //选择认证方式
    selectAttestationMode() {
      this.step--;
      this.show = true;
      this.Countdown = 60;
      this.code = "";
      this.codeErrorText = "";
      clearInterval(this.timer);
    },
    //获取新邮箱的验证码
    getNewEmailVerificationCode() {
      if (this.newIphoneStatus === true) {
        this.show = true;
        // 60秒倒计时
        this.show = false;
        this.timer = setInterval(() => {
          this.Countdown--;
          if (this.Countdown <= 0) {
            this.show = true;
            this.Countdown = 60;
            clearInterval(this.timer);
          }
        }, 1000);
        this.$api
        .getUpdateEmailVerificationCode({
          check_number_type: "email",
          new_email: this.newEmail,
        })
        .then((res) => {
          if (res.code != 0) {
            this.$message.error(res.msg)
            this.show = true;
            this.Countdown = 60;
            clearInterval(this.timer);
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../../css/mixin.scss";
@import "./css/userDetail.scss";
</style>
